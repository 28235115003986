$preferred_font: Spartan, sans-serif;
$performance_font: Spartan, sans-serif;
$color-primary: 255, 255, 255;
$text-color-primary: 255, 255, 255;
$input-text-label-color: rgba(#fff, 0.8);
$input-text-bottom-border-color: rgba(#fff, 0.24);
$image-path: "../images";

$checkbox-color: #fff;
$checkbox-off-color: #fff;
