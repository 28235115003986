.ee-job-offer-page__salary {
  font-size: 1.2rem;

  .salary {
    font-weight: 500;
  }

  .location {
    font-weight: 800;
  }
}
