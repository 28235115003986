// =================================
// MDL
//
// we use only a small subset of its components
// =================================
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "overrides/mdl.override";
@import "~material-design-lite/src/button/button";
@import "~material-design-lite/src/checkbox/checkbox";
@import "~material-design-lite/src/textfield/textfield";

// ======
// bulma
// ======
@import "~bulma/sass/utilities/initial-variables";
@import "~bulmaswatch/cyborg/variables";

@import "colors";
@import "constants";
@import "fonts";

@import "~bulma/bulma";
// we deliberately don't include bulmaswatch overrides for cyborg theme because they are making bulma less configurable
// (for example it's impossible to configure global card background color)
// @import "~bulmaswatch/cyborg/overrides";

// =================================
// UTILITIES
// =================================
@import "utilities/index.mixins";

// =================================
// SHARED
// =================================
@import "shared/index.shared";
@import "embed";

// =================================
// IMPORTS
// =================================
@import "pages/index.pages";
@import "blocks/index.blocks";
@import "overrides/index.overrides";

// ======
// hacking
// ======
.ee-has-background-image {
  position: relative;
}

.ee-background-image {
  height: 100%;
  left: 0;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
