$family-sans-serif: "Spartan", sans-serif;
$navbar-item-img-max-height: 3rem;
$card-background-color: rgba(255, 255, 255, 0.1);
$link: $grey-lighter;
$label-color: $grey-light;
$button-border-width: 2px;
$size-1: 4rem;
$radius: 15px;
$message-background-color: rgba(0, 0, 0, 0.5);
$message-body-border-width: 0;

// forms
$input-radius: 0;
$input-color: $white;
$input-background-color: transparent;
$input-focus-box-shadow-size: 0 0 0 0;
$input-hover-color: $grey-light;
$file-radius: 0;
$border: $white;

// content
$body-line-height: 1.3;
