@import "../utilities/index.mixins";

@mixin ee-navbar-item-typography {
  font-size: 0.75rem;
  font-weight: 900;
  letter-spacing: 2px;
}

.ee-navbar {
  background-color: transparent;
  background-image: linear-gradient(
    to bottom,
    rgba(#000, 0.9) 20%,
    rgba(#000, 0.3) 80%,
    rgba(#000, 0)
  );
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10500;

  @include touch {
    z-index: 20900;
  }
}

.ee-navbar-end {
  .ee-navbar-item {
    @include ee-navbar-item-typography;
    @include transition($time: 160ms);
    border-bottom: 1px solid rgba(#fff, 0);
    margin-right: 1vw;

    &:hover {
      background-color: unset;
      border-bottom: 1px solid #fff;
    }
  }

  .ee-contact-us-button {
    @include ee-navbar-item-typography;
    padding: 1rem 2rem;
    @include transition;

    &:hover {
      @include shadow;
    }

    @include touch {
      background: transparent;
      color: #000;
      font-size: 1.4rem;
      font-weight: 700;
      letter-spacing: 0;
    }
  }

  @include touch {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: 0;
    padding: 15rem 0;
    position: fixed;
    top: 0;
    width: 100vw;

    .ee-navbar-item {
      color: #000;
      font-size: 1.4rem;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 1.8;
      margin: 0;
    }
  }
}

.ee-navbar-close-button {
  display: none;
  position: absolute;
  right: 1rem;
  top: 1rem;

  @include touch {
    background-color: transparent;
    border: 0;
    display: block;
  }
}

.navbar-language-selector {
  .language {
    display: inline;
  }

  .button {
    @include ee-navbar-item-typography;
    @include transition($time: 160ms);
    border-width: 0;
    border-bottom-width: 1px;
    border-color: rgba(#fff, 0);

    &:hover {
      border-color: #fff;
    }
  }
}
