.ee-tiles {
  @include touch {
    grid-row-gap: 2rem;
    grid-template-columns: 1fr;
  }

  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
}

.ee-tile {
  @include touch {
    justify-content: center;
  }

  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
}

.ee-tile__image {
  height: 6rem; // take space even if there is no image inside
  object-fit: cover;

  img {
    height: 5rem;
    width: auto;
  }

  @include touch {
    width: auto;
  }
}

.ee-tile__background-image {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 12rem;
  justify-content: space-between;

  position: relative;
  width: 100%;
}

.ee-tile__headline {
  color: rgba(#fff, 0.7);
  padding: 1rem 2rem;
}

.ee-tile-fancy {
  align-items: center;
  border: 2px solid $white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  padding: 1rem;
}

.ee-tile-fancy__image {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  img {
    max-height: 3rem;
    max-width: 3rem;
  }
}

.ee-tile-fancy__spacer {
  @include transition;

  background-color: $white;
  height: 2px;
  position: relative;
  top: 50%;

  &::before {
    background-color: $white;
    border-radius: 4px;
    content: "";
    height: 8px;
    left: 0;
    position: absolute;
    top: -3px;
    width: 8px;
  }

  &::after {
    border-color: transparent transparent transparent $white;
    border-style: solid;
    border-width: 5px 0 5px 8.7px;
    content: "";
    height: 0;
    position: absolute;
    right: -1px;
    top: -4px;
    width: 0;
  }

  @include touch {
    display: none;
  }
}
