.ee-news-page__share-buttons {
  display: grid;
  grid-row-gap: 1rem;
  grid-template-rows: repeat(1, auto);
  position: sticky;
  position: -webkit-sticky;
  top: 6rem;

  @include touch {
    align-items: center;
    grid-column-gap: 1rem;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: unset;
    justify-content: center;

    &::before {
      content: "Podziel się";
    }
  }
}
