.mdl-checkbox {
  height: unset;
  margin: 0.5rem 0;
  min-height: 24px;
}

.mdl-button--file {
  input {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    z-index: 4;
  }
}

.mdl-textfield--file {
  .mdl-textfield__input {
    box-sizing: border-box;
    width: calc(100% - 32px);
  }

  .mdl-button--file {
    right: 0;
  }
}
