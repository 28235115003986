.ee-article-card__image {
  @include touch {
    flex-basis: 20rem;
  }

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 12rem;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;
}

.ee-article-card__headline {
  @include transition;

  color: rgba(#fff, 0.7);
  opacity: 0;
  padding: 1rem 2rem;
}

.ee-article-card__button {
  @include touch {
    padding: 0;
    width: 100%;

    & > * {
      width: 100%;
    }
  }

  padding: 0 2rem;
}

.ee-article-card__date {
  @include touch {
    font-weight: 700;
  }
}

.ee-article-card {
  @include transition;

  display: flex;
  min-height: 14rem;

  &:first-child {
    transform-origin: top right;
  }

  &:last-child {
    transform-origin: top left;
  }

  &:hover {
    transform: scale(1.05);

    .ee-article-card__image {
      @include shadow($size: xl, $tint: $white, $alpha: 0.2);
    }

    .ee-article-card__headline {
      opacity: 1;
    }
  }
}
