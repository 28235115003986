// =========
// aside sticky form
// =========

.ee-sticky-contact-form {
  @include transition;

  @include touch {
    padding: 0;
    width: 100vw;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 100vh;
  padding: 3rem 7rem;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: 40vw;
  z-index: 20010;

  &.active {
    transform: translateX(0);
  }
}

.ee-sticky-contact-form__trigger {
  content: "";
  cursor: pointer;
  height: 3rem;
  left: -3rem;
  position: absolute;
  width: 3rem;
}

// =================================
// Contact Form
// =================================

.ee-contact-form__textfield {
  width: 100%;
}

.ee-form-field-required {
  color: $red;
  font-size: 1.5rem;
  vertical-align: text-top;
}

.ee-form-field-required--small {
  font-size: 1rem;
}

.ee-contact-form__fields-container {
  display: grid;
}

.ee-contact-form--single {
  & .ee-contact-form__fields-container {
    grid-template-areas: "FIELDS" "RULES" "SUBMIT";
    grid-template-columns: 1fr;
  }
}

.ee-contact-form--double {
  & .ee-contact-form__fields-container {
    @include touch {
      grid-column-gap: 0;
      grid-template-areas: "FIELDS" "RULES" "SUBMIT";
      grid-template-columns: 1fr;
    }

    grid-column-gap: 8rem;
    grid-template-areas: "FIELDS RULES" ". SUBMIT";
    grid-template-columns: 1fr 1fr;
  }
}

.ee-contact-form__fields {
  grid-area: FIELDS;
}

.ee-contact-form__rules {
  display: flex;
  flex-direction: column;
  grid-area: RULES;
  justify-content: space-around;
}

.ee-contact-form__captcha {
  margin-top: 2rem;
}

.ee-contact-form__submit-button {
  grid-area: SUBMIT;
  margin-top: 2rem;
}
